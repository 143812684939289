import React from "react";
import { Helmet } from "react-helmet";
import { Head } from '@inertiajs/react';

const url = window.location.href;
const originUrl = window.location.origin;
const isFamilyFriendly = false;
const hasAdultConsideration = "WeaponConsideration";

export const Seo = ({pageTitle = "", title = "", description = "", img = "", page="", meta = []}) => {

    const cleanImg = img?.replace(/['"]+/g, '');

    const cleanDesc = description?.replace(/"/g, '');

    const name = [meta.make_verified,
                 meta.model_verified, 
                 meta.submodel_verified].join(' ').trim().split(/ +/).join(' ');

    const photoArray = meta.photo_array ? meta.photo_array.replace(/['"]+/g, '').replace(/\s/g, '').split(/,/) : '';
    if(Array.isArray(photoArray)) {
        photoArray.splice(1, 1); //remove promo image
    }
    
    const additionalProperty = [
        {
            "@type": "PropertyValue",
            "name": "Caliber",
            "value": meta.caliber_verified ? meta.caliber_verified : meta.caliber_other,
        },
        {
            "@type": "PropertyValue",
            "name": "Platform",
            "value": meta.platform
        },
        {
            "@type": "PropertyValue",
            "name": "Bore Condition",
            "value": meta.bore_condition
        },
        {
            "@type": "PropertyValue",
            "name": "Year Manufactured",
            "value": meta.year_manufactured
        },
        {
            "@type": "PropertyValue",
            "name": "Chokes",
            "value": meta.chokes
        },
        {
            "@type": "PropertyValue",
            "name": "Chamber",
            "value": meta.chamber
        },
        {
            "@type": "PropertyValue",
            "name": "Action Type",
            "value": meta.action_type
        },
        {
            "@type": "PropertyValue",
            "name": "Barrel Length",
            "value": meta.barrel_length
        },
        {
            "@type": "PropertyValue",
            "name": "Barrel Features",
            "value": meta.barrel_features
        },
        {
            "@type": "PropertyValue",
            "name": "Length of Pull (LOP)",
            "value": meta.lop
        },
        {
            "@type": "PropertyValue",
            "name": "Sights",
            "value": meta.sights
        },
        {
            "@type": "PropertyValue",
            "name": "Maximum Capacity",
            "value": meta.max_capacity
        },
        {
            "@type": "PropertyValue",
            "name": "Magazine Quantity",
            "value": meta.number_of_mags
        }
    ];

    Object.keys(additionalProperty).forEach(key => {
        if (additionalProperty[key].value === null || additionalProperty[key].value === undefined) {
          delete additionalProperty[key];
        }
    });

    const additionalPropertyClean = additionalProperty => Object.entries(additionalProperty)
    .map(([k,v])=>[k,v && typeof v === "object" ? cleanEmpty(v) : v])
    .reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {});

    const schema = {
        "@context": "https://schema.org",
        "@id": "#product",
        "@type": "IndividualProduct",
        "name": name,
        "image": photoArray,
        "brand": meta.make_verified,
        "model": meta.model_verified,
        "itemCondition": "http://schema.org/"+meta.overall_condition,
        "color": meta.finish_color,
        "logo": originUrl + "/images/firearmland-logo.png",
        "url": url,
        "category": meta.category_type,
        "keywords": meta.keywords,
        "isFamilyFriendly": isFamilyFriendly,
        "hasAdultConsideration": hasAdultConsideration,
        "serialNumber": meta.serial_number,
        "gtin13": meta.upc ? "0"+meta.upc : null,
        "mpn": meta.manufacturer_part_number,
        "countryOfOrigin": meta.country_of_origin,
        "audience": "firearm enthusiasts, gun enthusiasts, gun collectors, veterans, police officers"  ,
        "audienceType": "USA",
        "geographicArea": "USA",
        "additionalProperty": additionalPropertyClean
    };

    Object.keys(schema).forEach(key => {
        if (schema[key] === null) {
          delete schema[key];
        }
    });

    return(
        <>
        <Head>
        <title>{pageTitle === "" ? title : pageTitle}</title>
        </Head>         
        <Helmet
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        name: `description`,
                        content: cleanDesc,
                    },
                    {
                        property: 'og:locale',
                        content: 'en_US',
                    },
                    {
                        property: 'og:image',
                        content: cleanImg,
                    },                    
                    {
                        property: 'og:type',
                        content: 'website',
                    },     
                    {
                        property: 'og:title',
                        content: title + " | FirearmLand.com",
                    }, 
                    {
                        property: 'og:description',
                        content: cleanDesc,
                    }, 
                    {
                        property: 'og:url',
                        content: url,
                    }, 
                    {
                        property: 'og:site_name',
                        content: 'FirearmLand: Buy guns online',
                    },                 
                ]}
                link={[
                    {
                        hrtef: '/favicon.ico',
                        rel: 'hortcut icon'
                    },
                    {
                        rel: 'apple-touch-icon',
                        sizes: '57x57',
                        href: '/apple-icon-57x57.png'
                    },
                    {
                        rel: 'apple-touch-icon',
                        sizes: '60x60',
                        href: '/apple-icon-60x60.png'
                    },
                    {
                        rel: 'apple-touch-icon',
                        sizes: '76x76',
                        href: '/apple-icon-76x76.png'
                    },    
                    {
                        rel: 'apple-touch-icon',
                        sizes: '114x114',
                        href: '/apple-icon-114x114.png'
                    },
                    {
                        rel: 'apple-touch-icon',
                        sizes: '120x120',
                        href: '/apple-icon-120x120.png'
                    },   
                    {
                        rel: 'apple-touch-icon',
                        sizes: '144x144',
                        href: '/apple-icon-144x144.png'
                    },         
                    {
                        rel: 'apple-touch-icon',
                        sizes: '152x152',
                        href: '/apple-icon-152x152.png'
                    },      
                    {
                        rel: 'apple-touch-icon',
                        sizes: '180x180',
                        href: '/apple-icon-180x180.png'
                    },        
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '192x192',
                        href: '/android-icon-192x192.png'
                    },      
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '32x32',
                        href: '/favicon-32x32.png'
                    },  
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '96x96',
                        href: '/favicon-96x96.png'
                    },  
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '16x16',
                        href: '/favicon-16x16.png'
                    },
                    {
                        rel: 'canonical',
                        href: url
                    }                                                                                                                                    
                ]}
        >
            {(() => {
              if (page == 'product'){
                  return (
                    <script type="application/ld+json">
                        {JSON.stringify(schema)}
                    </script>
                  )
              }
            })()}
        </Helmet>
        </>
    )
}